/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const onInitialClientRender = () => {
  window.iFrameResize(
    {
      autoResize: true,
      checkOrigin: false,
      heightCalculationMethod: "max",
      enablePublicMethods: true,
    },
    "#RSVPifyIFrame"
  )
}
